import React from 'react';
import Layout from '../layouts';
import SEO from '../components/SEO';

const contact = () => (
  <Layout>
    <SEO title="Contact Joseph Chambers" />
    <section className="contact-page">
      <article className="contact-form">
        <h3>Let's Talk!</h3>
        <form name="contact" method="POST" action="/thanks" data-netlify="true">
          <div className="form-group">
            <input
              id="first-name"
              type="text"
              name="firstName"
              placeholder="First Name"
              required="required"
              className="form-control"
            />
            <input
              id="last-name"
              type="text"
              name="lastName"
              placeholder="Last Name"
              required="required"
              className="form-control"
            />
            <input
              id="tel-input"
              type="tel"
              name="phone"
              placeholder="(408) 372-0978"
              className="form-control"
            />
            <input
              id="email"
              type="email"
              name="name"
              aria-describedby="email-help"
              placeholder="Enter email"
              required="required"
              className="form-control"
            />
            <textarea
              id="message-box"
              name="message"
              rows="6"
              placeholder="How can I help you?"
              required="required"
              className="form-control"
            ></textarea>
          </div>
          <button type="submit" className="submit-btn btn">
            Email me
          </button>
        </form>
      </article>
    </section>
  </Layout>
);
export default contact;
